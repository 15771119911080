import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Link, Typography, Button, CircularProgress} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {APICall} from '../api/api';
import {IABcategoriesTable} from '../components/subcomponents/IABcategoriesTable';
import {DomainSelect} from '../components/subcomponents/Blocklist/DomainSelect';
import {CustomUrlList} from '../components/subcomponents/Blocklist/CustomUrlList';
import SendIcon from '@material-ui/icons/Send';
import useStore from '../store/store';
import useSnackbarSuccessOrError from '../components/subcomponents/subscription/customerSuccessBar';

const useStyles = makeStyles(theme => ({
  wrapper: {
    borderRadius: '4px',
  },
  viewWrapper: {
    padding: 50,
    [theme.breakpoints.down('sm')]: {
      padding: 15,
      paddingTop: 10,
    },
  },
  searchInputField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  heroImage: {maxWidth: '50%', margin: '0 auto', display: 'block', padding: 25},
}));

const BlockList = ({getTickets}) => {
  const classes = useStyles();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedDomains, setSelectedDomains] = useState(null);
  const [listOfUrls, setListofUrls] = useState([]);
  const [loading, setLoading] = useState(null);
  const auth = useStore(state => state.auth);
  const [callSnackbarSuccessError] = useSnackbarSuccessOrError();

  const submitTicket = async () => {
    setLoading(true);

    try {
      let submitResult = await APICall({
        endpoint: 'submitTicket',
        options: {
          method: 'POST',
          body: JSON.stringify({
            title: 'Blocklist request',
            summary: `
            Blocklist request for the following categories: ${selectedCategories.join(', ')}, 
            URLs to block: ${
              listOfUrls.length ? listOfUrls.map(url => url.label).join(', ') : 'none'
            }, 
            Domains to apply the rules: ${selectedDomains.map(domain => domain.label).join(', ')}
            `,
            issueType: 'Blocklist',
          }),
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Cache-Control': 'max-age=0, no-cache',
            'Content-Type': 'application/json',
          },
        },
      });

      if (!submitResult.success) {
        callSnackbarSuccessError(false, ``, `Internal server error`);
      } else {
        getTickets();

        callSnackbarSuccessError(
          true,
          <>
            The blocklist was successfully submitted. Visit the <Link href="/support">Support</Link>{' '}
            section to follow its status.
          </>,
          ``
        );
      }
    } catch (e) {
      callSnackbarSuccessError(false, ``, `Internal server error`);
    }
    setLoading(false);
  };

  return (
    <Grid
      container
      component="main"
      maxWidth="xl"
      className={classes.viewWrapper}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Typography variant={'h4'} gutterBottom>
          Blocklist management
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography gutterBottom>
          Setupad can implement blocklists only for their reseller accounts. The publisher is
          responsible for managing his direct account blocklist. Please visit the{' '}
          <Link href="/support">Support</Link> section to see the status of your request.
        </Typography>
      </Grid>

      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        style={{marginTop: 5, marginBottom: 5}}
      >
        <Grid item xs={6}>
          <Typography gutterBottom color={'textSecondary'} variant={'subtitle'} display={'block'}>
            1. Select one or more domains to apply the blocklist to
          </Typography>
          <DomainSelect selectedDomains={selectedDomains} setSelectedDomains={setSelectedDomains} />
        </Grid>
        <Grid item xs={6}>
          <Typography gutterBottom color={'textSecondary'} variant={'subtitle'} display={'block'}>
            2. Add specific advertisers to the blocklist
          </Typography>
          <CustomUrlList listOfUrls={listOfUrls} setListofUrls={setListofUrls} />
        </Grid>
      </Grid>

      <IABcategoriesTable
        setSelectedCategories={setSelectedCategories}
        selectedCategories={selectedCategories}
      />

      <Grid item xs={12} style={{marginTop: 30, marginBottom: 30}}>
        <Button
          variant="contained"
          color="primary"
          disabled={
            loading || !selectedDomains || (!selectedCategories.length && !listOfUrls.length)
          }
          startIcon={loading ? <CircularProgress size={14} /> : <SendIcon />}
          onClick={submitTicket}
        >
          {loading ? 'Please wait' : 'Submit request'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default React.memo(BlockList);
